*{
    margin: 0;
    scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}

.yellowColor {
  color: rgb(214, 164, 0) !important;
}