h4 {
  font-family: "Roboto Slab";
  font-size: 1vmax;
  margin-bottom: 1vmax;
}

/* footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
} */

footer {
  margin-top: 20vmax;
  padding: 5vmax 0vmax;
  background-color: #303030;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topFooter,
.bottomFooter {
  display: flex;
  flex-direction: row;
}

.topFooter {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  padding: 0vmax 3vmax 2vmax 2vmax;
}

.bottomFooter {
  padding: 2vmax;
  width: 80%;
  justify-content: space-between;
}

.leftFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.leftFooter-p > p,
.copyright, .atews{
  text-align: start;
  font-size: 1vmax;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
  "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.copyright{
  font-size: 1vmax;
  margin-bottom: 1vmax;
}

.atews{
  font-size: 1vmax;
}

.atews a {
  color: white; 
  text-decoration: none; 
  margin: 0 0.5vmax;
}

.atews a:hover {
  text-decoration: underline; 
}

.leftFooter-p > p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 0.5vmax 0;
}

.rightFooter,
.midFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightFooter > a,
.midFooter > a {
  text-decoration: none;
  font-size: 1vmax;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  margin: 0.5vmax 0;
}

.ig,
.fb,
.linkedin {
  color: white;
  width: 30%;
}

.links a {
  margin-right: 1vmax;
  margin-top: 2vmax;
}

@media screen and (max-width: 600px) {

  .topFooter,
  .bottomFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .leftFooter,
  .rightFooter,
  .midFooter {
    margin-bottom: 3vmax;
  }

  .ig,
  .fb,
  .linkedin {
    color: white;
    width: 5%;
  }

  .links{
    margin-bottom: 1vmax;
  }
}
