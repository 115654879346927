.loading {
  width: 100vw;
  height: 80vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  border: 10px solid #707070; 
  border-top: 10px solid #800039; 
  border-radius: 100%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}